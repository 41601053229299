import * as React from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useMsal } from "@azure/msal-react";
import { REACT_APP_BASE_URL } from "../settings"
import IconButton from "@mui/material/IconButton";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import Divider from '@mui/material/Divider';

export default function BasicMenu(props) {
  const { accounts, instance } = useMsal();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        <AccountCircleIcon style={{ color: "#FFF" }} />
      </IconButton>

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem onClick={handleClose}>{accounts[0].idTokenClaims.email}</MenuItem>
        <Divider variant="middle" />
        { 
        window.location.hostname === "devconnectclient.vnbizbox.com" || window.location.hostname === "qaconnectclient.vnbizbox.com" || window.location.hostname === "connectclient.vnbizbox.com" ? 
          <></> 
        :
          <>
            <MenuItem onClick={() => {
              props.onClickBack('/contact_us');
            }}>Contact Us</MenuItem>
            <Divider variant="middle" />
          </>
        }
        <MenuItem onClick={() => {
          instance.logoutRedirect({
            postLogoutRedirectUri: REACT_APP_BASE_URL,
          });

        }}>Logout</MenuItem>

      </Menu>
    </div>
  );
}
