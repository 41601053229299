import React, { useEffect } from "react";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import image from "./home_background.png";
import connectclient from "../image/connectclient.png";

function Home({ setPage, onLogin }) {

  useEffect(() => {
    if(window.location.hostname === 'connectclient.dksh.com' || window.location.hostname === 'connectclient.vnbizbox.com') {
      window.appInsights.trackPageView({name: 'Login'});
    }
  }, []);

  return (
      <div
        style={{
          backgroundImage: `url(${image})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          height: "91.3vh",
          maxHeight: "auto",
          width: "100%",
        }}
      >
      <Container>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            paddingTop: "8%",
          }}
        >
          <Typography
            variant="h2"
            component="div"
            gutterBottom
            sx={{ fontStyle: "Bold",fontWeight: 500 }}
          >
            Welcome To
          </Typography>
          <img
            src={connectclient}
            alt="ConnectClientLogo"
            style={{marginBottom: "50px", maxWidth: "420px",
            height: "auto",
            padding: 0}}
          />

          <Typography variant="h5" component="div" gutterBottom>
            Manage Your Business
          </Typography>

          <Typography variant="h5" component="div" gutterBottom>
            Anywhere, Anytime With Ease
          </Typography>
          <br></br>
          <br></br>
          <div
            style={{
              display: "flex",
              maxWidth: "450px",
              width: "auto",
              height: "10px",
              backgroundColor: "#AB1E34",
              border: 'none'
            }}
          >
          </div>
        </div>
        </Container>
        </div>
  );
}

export default Home;
