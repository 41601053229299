import React from "react";
import connectClientWhite from "../image/ConnectClient_DKSH_white.png";
import whiteConnectClient from "../image/whiteconnectclient.png";
import Box from "@mui/material/Box";

function Branding(props) {
  return (
    props.main ? (
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          alignContent: "center"
        }}
      >
        {
          window.location.hostname === "devconnectclient.vnbizbox.com" || window.location.hostname === "qaconnectclient.vnbizbox.com" || window.location.hostname === "connectclient.vnbizbox.com" ?
            <>
              <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
                <img src={whiteConnectClient} alt="ConnectClientLogo" height="18" />
              </Box>
              <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
                <img src={whiteConnectClient} alt="ConnectClientLogo" height="15" />
              </Box>
            </>
            :
            <>
              <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
                <img src={connectClientWhite} alt="ConnectClientLogo" height="30" />
              </Box>
              <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
                <img src={connectClientWhite} alt="ConnectClientLogo" height="20" />
              </Box>
            </>
        }
      </div>
    ) : (
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          alignContent: "center",
          //  paddingTop: '10px'
        }}
      >
        {
          window.location.hostname === "devconnectclient.vnbizbox.com" || window.location.hostname === "qaconnectclient.vnbizbox.com" || window.location.hostname === "connectclient.vnbizbox.com" ?
            <>
              <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
                <img src={whiteConnectClient} alt="ConnectClientLogo" height="18" />
              </Box>
              <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
                <img src={whiteConnectClient} alt="ConnectClientLogo" height="15" />
              </Box>
            </>
            :
            <>
              <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
                <img src={connectClientWhite} alt="ConnectClientLogo" height="30" />
              </Box>
              <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
                <img src={connectClientWhite} alt="ConnectClientLogo" height="20" />
              </Box>
            </>
        }

      </div>
    )
    // </div>
  );
}

export default Branding;
