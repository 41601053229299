import React, { useEffect } from "react";
import ContactUsPage from "../pages/ContactUsPage";
import BottomInfoBar from "../resusableComponent/BottomInfoBar";
import SubHeader from "../resusableComponent/SubHeader";
import { useMsal } from "@azure/msal-react";

const subHeading = "Contact Us";

function ContactUs(props) {

  const { accounts } = useMsal();
  const userEmail = accounts[0].idTokenClaims.email;

  useEffect(() => {
    if(window.location.hostname === 'connectclient.dksh.com' || window.location.hostname === 'connectclient.vnbizbox.com') {
      window.appInsights.setAuthenticatedUserContext(userEmail, userEmail);
      window.appInsights.trackPageView({name: 'Contact Us'});
    }
  }, []);

  const onClickBack = (path) => {
    props.history.push(path);
  };

  return (
    <div>
      <SubHeader 
        // back="back" 
        subHeading={subHeading} isImage="false" onClickBack={onClickBack} path='/' />
      <ContactUsPage main="true" onClickBack={onClickBack} path='/'/>
      <BottomInfoBar />
    </div>
  );
}

export default ContactUs;
