import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Branding from "./Branding";
import HomeIcon from "@mui/icons-material/Home";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import Menu from "./Menu";

export default function ButtonAppBar(props) {

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static" sx={{ backgroundColor: "#AB1E34" }}>
        <Toolbar
          style={{ minHeight: "0px" }}
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignContent: "center",
              alignItems: "center",
            }}
          >
            {props.back ? (
              <>
                <IconButton
                  aria-label="Home"
                  onClick={() => {
                    props.onClickBack("/");
                  }}
                >
                  <HomeIcon style={{ color: "#ffffff" }} />
                </IconButton>
                {props.isReload === "true" ? (
                <IconButton
                  aria-label="BackButton"
                  onClick={() => {
                    props.onClickReload(props.path);
                  }}
                >
                  <ArrowLeftIcon style={{ color: "#ffffff", fontSize: "30px" }} />
                </IconButton>
                 ) : (
                  <IconButton
                  aria-label="BackButton"
                  onClick={() => {
                    props.onClickBack(props.path);
                  }}
                >
                  <ArrowLeftIcon style={{ color: "#ffffff", fontSize: "30px" }} />
                </IconButton>
                )}
              </>
            ) : (
              <IconButton
                  aria-label="Home"
                  onClick={() => {
                    props.onClickBack("/");
                  }}
                >
                  <HomeIcon style={{ color: "#ffffff" }} />
                </IconButton>
            )}
            <Box main="true" sx={{ display: { xs: 'none', md: 'flex' } }}>
              {props.isImage === "true" ? (
                <img src={props.image} alt="Navigator" height="25px" />
              ) : (
                <span style={{ color: "#ffffff" }}>
                  <div style={{ fontWeight: 500 }}>{props.subHeading}</div>
                </span>
              )}
            </Box>
          </div>

          <div>
            <div style={{ display: "flex" }}>
              <Menu onClickBack={props.onClickBack} />
              <Branding main="true" />
            </div>
          </div>
        </Toolbar>
      </AppBar>

      {/* <BottomInfoBar /> */}
    </Box>
  );
}
