import React from "react";
import { MsalProvider, useMsal, useIsAuthenticated } from "@azure/msal-react";
import { PageLayout } from "./ui.jsx";
import LoginContent from "./LoginContent";
import "./styles/App.css";

/**
 * Most applications will need to conditionally render certain components based on whether a user is signed in or not.
 * msal-react provides 2 easy ways to do this. AuthenticatedTemplate and UnauthenticatedTemplate components will
 * only render their children if a user is authenticated or unauthenticated, respectively. For more, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/getting-started.md
 */

const MainContent = () => {
  const isAuthenticated = useIsAuthenticated();
  const { inProgress } = useMsal();

  return (
    <>
      {isAuthenticated && inProgress !== "logout" ? (
        <>
          <LoginContent />
        </>
      ) : (
        <></>
      )}

      {!isAuthenticated && <></>}
    </>
  );
};

/**
 * msal-react is built on the React context API and all parts of your app that require authentication must be
 * wrapped in the MsalProvider component. You will first need to initialize an instance of PublicClientApplication
 * then pass this to MsalProvider as a prop. All components underneath MsalProvider will have access to the
 * PublicClientApplication instance via context as well as all hooks and components provided by msal-react. For more, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/getting-started.md
 */
export default function App({ msalInstance }) {
  return (
    <MsalProvider instance={msalInstance}>
      <PageLayout>
        <MainContent />
      </PageLayout>
    </MsalProvider>
  );
}
