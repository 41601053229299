import React from "react";
import Typography from "@mui/material/Typography";
import imagered from "./aboutusredd.png";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Divider from '@mui/material/Divider';

const addressData = [
  {
    heading: "DKSH Vietnam Co., Ltd.",
    address:
      "5th Floor, Viettel Complex, 285 Cach Mang Thang Tam Street, Ward 12, District 10 70000 Ho Chi Minh City",
    phone: "+84 28 3812 5848",
    fax: "",
    email: "",
    country: "Vietnam",
  },
  {
    heading: "DKSH Hong Kong",
    address:
      "23rd Floor, Tower A, Southmark, 11 Yip Hing Street, Wong Chuk Hang",
    phone: "+852 2895 0888",
    fax: "+852 2577 1057",
    email: "",
    country: "Hong Kong",
  },
  {
    heading: "PT DKSH Indonesia",
    address:
      "AIA Center 39th floor. Jalan Jendral Sudirman Kav. 48A. 12930 Jakarta Selatan",
    phone: "+62 21 6909 244",
    fax: "+62 21 691 8328",
    email: "",
    country: "Indonesia",
  },
  {
    heading: "DKSH Malaysia Sdn. Bhd. (196101000441 (4476-U))",
    address:
      "B-11-01, The Ascent, Paradigm, No. 1, Jalan SS7/26A, Kelana Jaya 47301 Petaling Jaya Selangor",
    phone: "+60 3 7882 8888",
    fax: "+60 3 7882 5555",
    email: "",
    country: "Malaysia",
  },
  {
    heading: "DKSH Myanmar",
    address: "No.13, Thitsar Road, Yankin Township Yangon",
    phone: "+95 1 3565270",
    fax: "",
    email: "",
    country: "Myanmar",
  },
  {
    heading: "DKSH Taiwan Ltd",
    address:
      "10th Floor, No.22, Lane 407, Section 2, Tiding Boulevard, Neihu District 11493 Taipei",
    phone: "+886 2 8752 6666",
    fax: "",
    email: "",
    country: "Taiwan",
  },
  {
    heading: "DKSH (Thailand) Limited",
    address:
      "Fantree 1 Building 2533 Sukhumvit Road, Bangchak, Phrakhanong 10260 Bangkok",
    phone: "+66 2 790 4000",
    fax: "+66 2 742 6922",
    email: "",
    country: "Thailand",
  },
];

function ContactUsPage(props) {
  const [data, setData] = React.useState(addressData);
  const [country, setCountry] = React.useState("");
  const [open, setOpen] = React.useState(false);

  const handleChange = (event) => {
    setCountry(event.target.value);
    const filterData = addressData.filter((a) => {
      return a.country.match(event.target.value);
    });
    setData(filterData);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <>
      <div
        style={{
          backgroundImage: `url(${imagered})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          // height: "100%",
          // width: "100%",
        }}
      >
        <div
          style={{
            display: "flex",
            height: "83vh",
            paddingTop: "10px",
            maxWidth: "500px",
            width: "auto",
            paddingLeft: "12px",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              paddingLeft: "2%",
              paddingTop: "2%",
              // width: "3000px",
              textAlign: "start",
            }}
          >
            <div>
              <FormControl sx={{ minWidth: 320 }}>
                <InputLabel
                  color="error"
                  id="demo-controlled-open-select-label"
                >
                  Select a Market
                </InputLabel>
                <Select
                  labelId="demo-controlled-open-select-label"
                  id="demo-controlled-open-select"
                  open={open}
                  onClose={handleClose}
                  onOpen={handleOpen}
                  value={country}
                  label="Select a Market"
                  onChange={handleChange}
                  color="error"
                >
                  {addressData.map((item) => (
                    <MenuItem value={item.country}>{item.country}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <br />
            {data.map((add) => (
              <>
                <Typography
                  variant="h6"
                  component="div"
                  gutterBottom
                  sx={{ fontStyle: "Bold" }}
                >
                  {add.heading}
                </Typography>
                <Typography variant="inherit" gutterBottom>
                  {add.address}
                  <br />
                  {add.country}
                  <br />
                  {add.phone ? <>Phone: {add.phone} <br /></> : null}
                  {add.fax ? <>Fax: {add.fax} <br /></> : null}
                  {add.email ? <>Email: {add.email}</> : null}
                </Typography>
                <Divider sx={{mt: 1, mb: 1}} />
              </>
            ))}
            <br />
          </div>{" "}
          {/* <Stepper /> */}
        </div>
      </div>
    </>
  );
}

export default ContactUsPage;
