import { Typography } from "@mui/material";
import React from "react";

function BottomInfoBar() {
  return (
    <div
      style={{
        display: "flex",
        width: "100%",
        height: "31px",
        position: "fixed",
        backgroundColor: "#000000",
        color: "#ffffff",
        bottom: 0,
        paddingTop: "4px",
      }}
    >
      { 
        window.location.hostname === "devconnectclient.vnbizbox.com" || window.location.hostname === "qaconnectclient.vnbizbox.com" || window.location.hostname === "connectclient.vnbizbox.com" ? 
          <></> 
        :
        <Typography variant="body2" component="div" sx={{ paddingLeft: "35px", paddingTop: "2px", paddingBottom: "2px", fontSize: "0.800rem" }}>
          Delivering Growth - In Asia And Beyond
        </Typography>
      }
    </div>
  );
}

export default BottomInfoBar;
