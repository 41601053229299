import React from "react";
import IconButton from "@mui/material/IconButton";
import DownloadIcon from "@mui/icons-material/Download";

function InfoBar(props) {
  return (
    <div style={{ display: "flex", justifyContent: "flex-end" }}>
      <div style={{ paddingRight: "15px" }}>
        <IconButton aria-label="Home" onClick={() => props.exportVisual()}>
          <DownloadIcon style={{ color: "#404040", fontSize: "20px" }} />
        </IconButton>
      </div>
    </div>
  );
}

export default InfoBar;
