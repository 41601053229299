import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Contact from "../pages/ContactUsPage";
import Home from "../pages/Home";
import Administrative from "../pages/AdministrativePage";
import Analytics from "../pages/AnalyticsPage";
import Branding from "./Branding";
import BottomInfoBar from "./BottomInfoBar";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";


export default function ButtonAppBar({ onLogin }) {

  const [page, setPage] = React.useState("home");
  const handleTabClick = (page) => {
    setPage(page);
  };

  const renderPage = () => {
    if (page === "home") return <Home setPage={setPage} onLogin={onLogin} />;
    if (page === "administrative") return <Administrative />;
    if (page === "analytical") return <Analytics />;
    if (page === "contact") return <Contact setPage={setPage} />;
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static" sx={{ backgroundColor: "#AB1E34" }}>
        <Toolbar
          style={{
            minHeight: "0px", paddingLeft: "12px",
            paddingRight: "12px"
          }}
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div style={{ display: "flex" }}>
            {/* <Typography variant="h6" component="div" sx={{ paddingRight: '10px' }}>
              Menu
            </Typography> */}
            {/* <Button
              color="inherit"
              sx={{ color: "#ffffff", fontWeight: "600" }}
              onClick={() => handleTabClick("home")}
            >
              Home
            </Button> */}
            {/* <AppBarMenu handleTabClick={handleTabClick} /> */}
            {page === "contact" ? (
              <IconButton
                aria-label="BackButton"
                onClick={() => handleTabClick("home")}
              >
                <ArrowLeftIcon style={{ color: "#ffffff", fontSize: "30px" }} />
              </IconButton>
            ) : (null)}
            { 
            window.location.hostname === "devconnectclient.vnbizbox.com" || window.location.hostname === "qaconnectclient.vnbizbox.com" || window.location.hostname === "connectclient.vnbizbox.com" ? 
              <></> 
            :
              <>
                <Button
                  color="inherit"
                  sx={{
                    color: "#ffffff",
                    fontWeight: "400",
                    textTransform: "none",
                    fontSize: "large",
                    display: { xs: 'none', md: 'flex' }
                  }}
                  onClick={() => handleTabClick("contact")}
                >
                  Contact Us
                </Button>
                <Button
                  color="inherit"
                  sx={{
                    color: "#ffffff",
                    fontWeight: "400",
                    textTransform: "none",
                    fontSize: "small",
                    display: { xs: 'flex', md: 'none' }
                  }}
                  onClick={() => handleTabClick("contact")}
                >
                  Contact Us
                </Button>
              </>
            }
            <Button
              color="inherit"
              sx={{
                color: "#ffffff",
                fontWeight: "400",
                textTransform: "none",
                fontSize: "large",
                display: { xs: 'none', md: 'flex' }
              }}
              onClick={onLogin}
            >
              Login
            </Button>
            <Button
              color="inherit"
              sx={{
                color: "#ffffff",
                fontWeight: "400",
                textTransform: "none",
                fontSize: "small",
                display: { xs: 'flex', md: 'none' }
              }}
              onClick={onLogin}
            >
              Login
            </Button>
          </div>
          <Branding />
          {/* <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 3 }}
          >
            <MenuIcon />
          </IconButton> */}
        </Toolbar>
      </AppBar>

      {renderPage()}
      <BottomInfoBar />
    </Box>
  );
}
