const {
    REACT_APP_CLIENT_ID_FOR_DKSH,
    REACT_APP_AUTHORITY_URL_FOR_DKSH,
    REACT_APP_CLIENT_ID_FOR_CLIENT,
    REACT_APP_AUTHORITY_URL_FOR_CLIENT,
    REACT_APP_LOCAL_BASE_URL,
    REACT_APP_DEV_BASE_URL,
    REACT_APP_QA_BASE_URL,
    REACT_APP_PRD_BASE_URL,
    REACT_APP_DEV_JAVA_BASE_URL,
    REACT_APP_QA_JAVA_BASE_URL,
    REACT_APP_PRD_JAVA_BASE_URL,
    REACT_APP_DEV_SUBSCRIPTION_KEY,
    REACT_APP_QA_SUBSCRIPTION_KEY,
    REACT_APP_PRD_SUBSCRIPTION_KEY,
    REACT_APP_FIORI_APP_DEV_BASE_URL,
    REACT_APP_FIORI_APP_QA_BASE_URL,
    REACT_APP_FIORI_APP_PRD_BASE_URL,
    REACT_APP_DEV_BASE_URL_FOR_CLIENT,
    REACT_APP_QA_BASE_URL_FOR_CLIENT,
    REACT_APP_PRD_BASE_URL_FOR_CLIENT,
} = process.env;

let REACT_APP_BASE_URL = REACT_APP_LOCAL_BASE_URL;
let REACT_APP_JAVA_BASE_URL = REACT_APP_DEV_JAVA_BASE_URL;
let REACT_APP_SUBSCRIPTION_KEY = REACT_APP_DEV_SUBSCRIPTION_KEY;
let REACT_APP_FIORI_APP_BASE_URL = REACT_APP_FIORI_APP_DEV_BASE_URL;
let REACT_APP_CLIENT_ID = REACT_APP_CLIENT_ID_FOR_DKSH;
let REACT_APP_AUTHORITY_URL = REACT_APP_AUTHORITY_URL_FOR_DKSH;

if(window.location.hostname === 'devconnectclient.dksh.com') {
    REACT_APP_JAVA_BASE_URL = REACT_APP_DEV_JAVA_BASE_URL;
    REACT_APP_BASE_URL = REACT_APP_DEV_BASE_URL;
    REACT_APP_SUBSCRIPTION_KEY = REACT_APP_DEV_SUBSCRIPTION_KEY;
    REACT_APP_FIORI_APP_BASE_URL = REACT_APP_FIORI_APP_DEV_BASE_URL;
    REACT_APP_CLIENT_ID = REACT_APP_CLIENT_ID_FOR_DKSH;
    REACT_APP_AUTHORITY_URL = REACT_APP_AUTHORITY_URL_FOR_DKSH;
}
if(window.location.hostname === 'qaconnectclient.dksh.com' ) {
    REACT_APP_JAVA_BASE_URL = REACT_APP_QA_JAVA_BASE_URL;
    REACT_APP_BASE_URL = REACT_APP_QA_BASE_URL;
    REACT_APP_SUBSCRIPTION_KEY = REACT_APP_QA_SUBSCRIPTION_KEY;
    REACT_APP_FIORI_APP_BASE_URL = REACT_APP_FIORI_APP_QA_BASE_URL;
    REACT_APP_CLIENT_ID = REACT_APP_CLIENT_ID_FOR_DKSH;
    REACT_APP_AUTHORITY_URL = REACT_APP_AUTHORITY_URL_FOR_DKSH;
}
if(window.location.hostname === 'connectclient.dksh.com') {
    REACT_APP_JAVA_BASE_URL = REACT_APP_PRD_JAVA_BASE_URL;
    REACT_APP_BASE_URL = REACT_APP_PRD_BASE_URL;
    REACT_APP_SUBSCRIPTION_KEY = REACT_APP_PRD_SUBSCRIPTION_KEY;
    REACT_APP_FIORI_APP_BASE_URL = REACT_APP_FIORI_APP_PRD_BASE_URL;
    REACT_APP_CLIENT_ID = REACT_APP_CLIENT_ID_FOR_DKSH;
    REACT_APP_AUTHORITY_URL = REACT_APP_AUTHORITY_URL_FOR_DKSH;
}

if(window.location.hostname === 'devconnectclient.vnbizbox.com') {
    REACT_APP_JAVA_BASE_URL = REACT_APP_DEV_JAVA_BASE_URL;
    REACT_APP_BASE_URL = REACT_APP_DEV_BASE_URL_FOR_CLIENT;
    REACT_APP_SUBSCRIPTION_KEY = REACT_APP_DEV_SUBSCRIPTION_KEY;
    REACT_APP_FIORI_APP_BASE_URL = REACT_APP_FIORI_APP_DEV_BASE_URL;
    REACT_APP_CLIENT_ID = REACT_APP_CLIENT_ID_FOR_CLIENT;
    REACT_APP_AUTHORITY_URL = REACT_APP_AUTHORITY_URL_FOR_CLIENT;
}
if(window.location.hostname === 'qaconnectclient.vnbizbox.com' ) {
    REACT_APP_JAVA_BASE_URL = REACT_APP_QA_JAVA_BASE_URL;
    REACT_APP_BASE_URL = REACT_APP_QA_BASE_URL_FOR_CLIENT;
    REACT_APP_SUBSCRIPTION_KEY = REACT_APP_QA_SUBSCRIPTION_KEY;
    REACT_APP_FIORI_APP_BASE_URL = REACT_APP_FIORI_APP_QA_BASE_URL;
    REACT_APP_CLIENT_ID = REACT_APP_CLIENT_ID_FOR_CLIENT;
    REACT_APP_AUTHORITY_URL = REACT_APP_AUTHORITY_URL_FOR_CLIENT;
}
if(window.location.hostname === 'connectclient.vnbizbox.com') {
    REACT_APP_JAVA_BASE_URL = REACT_APP_PRD_JAVA_BASE_URL;
    REACT_APP_BASE_URL = REACT_APP_PRD_BASE_URL_FOR_CLIENT;
    REACT_APP_SUBSCRIPTION_KEY = REACT_APP_PRD_SUBSCRIPTION_KEY;
    REACT_APP_FIORI_APP_BASE_URL = REACT_APP_FIORI_APP_PRD_BASE_URL;
    REACT_APP_CLIENT_ID = REACT_APP_CLIENT_ID_FOR_CLIENT;
    REACT_APP_AUTHORITY_URL = REACT_APP_AUTHORITY_URL_FOR_CLIENT;
}

export {
    REACT_APP_BASE_URL,
    REACT_APP_JAVA_BASE_URL,
    REACT_APP_SUBSCRIPTION_KEY,
    REACT_APP_CLIENT_ID,
    REACT_APP_AUTHORITY_URL,
    REACT_APP_FIORI_APP_BASE_URL
};