import React from "react";
import AppBar from "./resusableComponent/AppBar";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsal,
} from "@azure/msal-react";
import { loginRequest } from "./authConfig";
import Backdrop from "./resusableComponent/Backdrop";

const NavigationBar = (props) => {
  /**
   * useMsal is hook that returns the PublicClientApplication instance,
   * an array of all accounts currently signed in and an inProgress value
   * that tells you what msal is currently doing. For more, visit:
   * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/hooks.md
   */
  const { instance, inProgress } = useMsal();
  const onLogin = () => {
    instance.loginRedirect(loginRequest);
  };

  return (
    <>
      <AuthenticatedTemplate>
        {inProgress === "logout" ? <Backdrop /> :
          <></>
        }
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        {inProgress === "logout" ? <Backdrop /> : <AppBar onLogin={onLogin} />}
      </UnauthenticatedTemplate>
    </>
  );
};

export const PageLayout = (props) => {
  return (
    <>
      <NavigationBar />
      {props.children}
      <br />
    </>
  );
};

export const IdTokenClaims = (props) => {
  return (
    <div id="token-div">
      <p>
        <strong>Audience: </strong> {props.idTokenClaims.aud}
      </p>
      <p>
        <strong>Issuer: </strong> {props.idTokenClaims.iss}
      </p>
      <p>
        <strong>OID: </strong> {props.idTokenClaims.oid}
      </p>
      <p>
        <strong>UPN: </strong> {props.idTokenClaims.preferred_username}
      </p>
    </div>
  );
};
