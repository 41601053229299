import React, { useEffect } from "react";
import SubHeader from "../resusableComponent/SubHeader";
import "./navigator.css";
import navigatorWhite from "./navigatorWhite.png";
import navigationImage from "./navigationPage.png";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import SingleActionDialog from "../resusableComponent/SingleActionDialog";
import Box from "@mui/material/Box";
import { useMsal } from "@azure/msal-react";
import BottomInfoBar from "../resusableComponent/BottomInfoBar";

const subHeading = "Navigator";

function Navigator(props) {
  const { accounts } = useMsal();
  const userEmail = accounts[0].idTokenClaims.email;
  const [singleOpen, setSingleOpen] = React.useState(false);
  const [msg, setMsg] = React.useState("");

  useEffect(() => {
    if(window.location.hostname === 'connectclient.dksh.com' || window.location.hostname === 'connectclient.vnbizbox.com') {
      window.appInsights.setAuthenticatedUserContext(userEmail, userEmail);
      window.appInsights.trackPageView({ name: 'Navigator' });
    }
  }, []);

  const onClickBackF = (path, cardType, checkAccess) => {
    if (checkAccess === "true") {
      props.history.push(path);
    } else {
      if (cardType === "standardpbi") {
        setMsg("Please Contact us to get Access to Lite Standard Dashboard");
        setSingleOpen(true);
      }
      if (cardType === "custompbi") {
        setMsg("Please Contact us to get Access to Lite Custom Dashboard");
        setSingleOpen(true);
      }
      if (cardType === "propbi") {
        setMsg("Please contact us to get access to Pro Products");
        setSingleOpen(true);
      }
      if (cardType === "avantpbi") {
        setMsg("Please contact us to get access to Avant Products");
        setSingleOpen(true);
      }
    }
  };

  const onClickBack = (path) => {
    props.history.push(path);
  };

  const sections = [
    {
      title: "Lite",
      subheader: "",
      cardColor: "#AB1E34",
      description: "Essential  Data for  business Planning​",
      buttonBorder: "1px solid #AB1E34",
      buttonText: "Standard",
      buttonVariant: "outlined",
      buttonPath: "/connect_client/navigator/lite_standard",
      hoverMsg: "Standard Hover message",
      checkAccess: "true",
      cardType: "standardpbi",
      buttonTwoText: "Custom",
      buttonTwoVariant: "contained",
      buttonTwoPath: "/connect_client/navigator/lite_custom",
      hoverMsgTwo: "Custom Hover message",
      checkCustomAccess: sessionStorage.getItem("customAccess"),
      customeCardType: "custompbi",
    },
    {
      title: "Pro",
      subheader: "",
      cardColor: "#AB1E34",
      description: "Market Insights to uncover opportunities",
      buttonBorder: "1px solid #AB1E34",
      buttonText: "Learn More",
      buttonVariant: "outlined",
      buttonPath: "/connect_client/navigator/pro_dashboard",
      hoverMsg: "Pro Hover message",
      checkAccess: sessionStorage.getItem("proPbiAccess"),
      cardType: "propbi",
    },
    {
      title: "Avant",
      subheader: "",
      cardColor: "#AB1E34",
      description:
        "Optimise Business  Performance with Advance Analytics solutions",
      buttonBorder: "1px solid #AB1E34",
      buttonText: "Learn More",
      buttonVariant: "outlined",
      buttonPath: "/connect_client/navigator/avant_dashboard",
      hoverMsg: "Avant Hover message",
      checkAccess: "true",
      cardType: "avantpbi",
    },
  ];

  return (
    <>
      <SingleActionDialog open={singleOpen} msg={msg} setOpen={setSingleOpen} />
      <div>
        <SubHeader
          back=""
          subHeading={subHeading}
          isImage="true"
          image={navigatorWhite}
          onClickBack={onClickBack}
          path="/"
        />
      </div>
      <Box
        style={{
          backgroundImage: `url(${navigationImage})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
        sx={{ display: { xs: 'none', md: 'flex' } }}>
        <div style={{ height: "45vh" }}></div>
      </Box>
      <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
        <div style={{ height: "15vh" }}></div>
      </Box>

      <Container>
        <Grid
          container
          spacing={6}
          direction="row"
          alignItems="top"
          justifyContent="center"
          style={{ marginTop: "-113px" }}
        >
          {sections.map((section) => (
            <Grid item key={section.title} xs={12} sm={6} md={4}>
              <Card style={{ borderBottom: "5px solid #AB1E34" }}>
                <CardContent>
                  <Typography
                    variant="h5"
                    color="text.black"
                    sx={{ textAlign: "center" }}
                    style={{ fontWeight: "600" }}
                  >
                    {section.title}
                  </Typography>
                  <hr style={{ borderTop: "0px solid black", opacity: "1" }} />
                  <br />
                  <br />
                  <Typography
                    variant="h6"
                    color="text.black"
                    sx={{ textAlign: "center" }}
                    style={{ fontWeight: "400" }}
                  >
                    {section.description}
                  </Typography>
                  <br />
                  <br />
                </CardContent>
                <CardActions>
                  <Button
                    fullWidth
                    onClick={() =>
                      onClickBackF(
                        section.buttonPath,
                        section.cardType,
                        section.checkAccess
                      )
                    }
                    size="large"
                    variant={section.buttonVariant}
                    style={{
                      color: section.cardColor,
                      border: section.buttonBorder,
                      textTransform: "none",
                    }}
                  >
                    {section.buttonText}
                  </Button>
                  {section.buttonTwoVariant && section.buttonTwoText ? (
                    <Button
                      fullWidth
                      onClick={() =>
                        onClickBackF(
                          section.buttonTwoPath,
                          section.customeCardType,
                          section.checkCustomAccess
                        )
                      }
                      size="large"
                      variant={section.buttonTwoVariant}
                      style={{
                        color: "#FFF",
                        backgroundColor: section.cardColor,
                        textTransform: "none",
                      }}
                    >
                      {section.buttonTwoText}
                    </Button>
                  ) : null}
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>
        <br />
      </Container>

      <BottomInfoBar />
    </>
  );
}

export default Navigator;
