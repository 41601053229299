import React, { useEffect }from "react";
import SubHeader from "../resusableComponent/SubHeader";
import "./InventoryTracking.css";
import BottomInfoBar from "../resusableComponent/BottomInfoBar";
import { REACT_APP_FIORI_APP_BASE_URL } from "../settings"
import { useMsal } from "@azure/msal-react";

const back = "back";
const subHeading = "Administrative & Operation";

function InventoryTracking(props) {

  const { accounts } = useMsal();
  const userEmail = accounts[0].idTokenClaims.email;

  
  useEffect(() => {
    if(window.location.hostname === 'connectclient.dksh.com' || window.location.hostname === 'connectclient.vnbizbox.com') {
      window.appInsights.setAuthenticatedUserContext(userEmail, userEmail);
      window.appInsights.trackPageView({name: 'Administrative & Operation'});
    }
  }, []);

  const onClickBack = (path) => {
    props.history.push(path);
  };

  const onClickReload = () => {
    window.location.reload(true);
  };

  return (
    <>
      <div>
        <SubHeader
          back={back}
          subHeading={subHeading}
          isImage="false"
          isReload="true"
          onClickBack={onClickBack}
          onClickReload={onClickReload}
          path="/connect_client/administrative_operation"
        />
      </div>
      <iframe
        title="Administrative and Operation"
        width="100%"
        height="96vh"
        style={{
          position: "fixed",
          top: "0",
          left: "0",
          zIndex: "-1",
          height: "96vh"
        }}
        src={REACT_APP_FIORI_APP_BASE_URL}
      />
      <BottomInfoBar />
    </>
  );
}

export default InventoryTracking;
